<!-- 行业政策 -->
<template>
    <div>
        <div class="header-box">
            <div class="header">
                <div class="header-text">
                    <div class="header-title">国家新安法规定企业必须构建双重预防机制</div>
                    <div class="header-content">
                        危化品、钢铁、工矿等企业事故频发，缺乏有效的预防风险的工具。通过双重预防机制管理系统，可以提前预知企业风险，提前排查预防，将关口前移，遏制事故，有效提升企业安全管控能力
                    </div>
                    <div class="header-bottom">
                        <span>金企盾双重预防机制管理系统，是企业落地双重预防机制的有效保障</span>
                        <button class="btn" @click="openDialog">预约体验</button>
                    </div>
                </div>
            </div>
            <div class="header-mask"></div>
        </div>

        <!-- 行业政策 -->
        <div class="industry">
            <div class="left-industry">
                <div class="left-title">
                    <div class="title-text">行业政策</div>
                    <div class="title-divider"></div>
                </div>
                <div class="industry-law">
                    <img class="law-img" :src="imgURL(articleList.typeImg)" alt="暂无图片">
                    <div class="law-text">
                        <div class="law-text-1">
                            <span>{{ articleList.title }}</span>
                            <span>{{ articleList.releaseDate?.slice(0, 10) }}</span>
                        </div>
                        <div class="law-content">
                            <div>{{ articleList.description == 'null' ? '' : articleList.description }}</div>
                            <span @click="openDialog">阅读原文>></span>
                        </div>
                    </div>
                </div>

                <div class="artical-text" style="width:100%;margin-top: 50px;">
                    <ul>
                        <li @click=openDialog v-for="item in articleLists" :key="item.id">
                            <div style="display:flex;justify-content: space-between;">
                                <span class="text-1">{{ item?.title }}</span>
                                <span style="font-size:14px;color: #9CA7B1;font-weight: 400;">{{
                                        item?.releaseDate?.slice(0, 10)
                                }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn-right">
                    <div class="btn-industry">
                        <el-button :disabled="industryPageNo == 1"
                            :class="industryPageNo == 1 ? 'left-arrow' : 'left-arrow blue'" @click="preClick">
                            <span class="el-icon-arrow-left"></span>
                        </el-button>
                        <el-button :class="industyTotalPage == industryPageNo ? 'right-arrow' : ' right-arrow blue'"
                            :disabled="industyTotalPage == industryPageNo" @click="nextClick"><i
                                class="el-icon-arrow-right"></i></el-button>
                    </div>
                </div>

            </div>
            <div class="right-industry">
                <div class="right-top">
                    <span @click="openDialog" class="key-words" v-for="item in spanList" :key="item.id">{{ item.name
                    }}</span>
                </div>
                <div style="width:370px">
                    <div class="right-medium">
                        <div class="right-medium-mask"></div>
                        <span>相关政策宣传</span>
                    </div>
                    <div class="right-bottom">
                        <div class="bottom-box-1">
                            <div class="bottom-box1-mask"></div>
                            <span>文件</span>
                        </div>
                        <div class="bottom-box-2">
                            <div class="bottom-box2-mask"></div>
                            <span>文件</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- 地方政策 -->
        <div class="industry">
            <div class="left-industry">
                <div class="left-title">
                    <div class="title-text">地方政策</div>
                    <div class="title-divider"></div>
                </div>
                <div class="industry-law">
                    <img class="law-img" :src="imgURL(placeList.typeImg)" alt="">
                    <div class="law-text">
                        <div class="law-text-1">
                            <span>{{ placeList.title }}</span>
                            <span>{{ placeList.releaseDate?.slice(0, 10) }}</span>
                        </div>
                        <div class="law-content">
                            <div>{{ placeList.description }}</div>
                            <span @click="openDialog">阅读原文>></span>
                        </div>
                    </div>
                </div>

                <div class="artical-text" style="width:100%;margin-top: 80px;">
                    <ul>
                        <li @click=openDialog v-for="item in placeListSplice" :key="item.id">
                            <div style="display:flex;justify-content: space-between;">
                                <span class="text-1">{{ item?.title }}</span>
                                <span style="font-size:14px;color: #9CA7B1;font-weight: 400;">{{
                                        item?.releaseDate?.slice(0, 10)
                                }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn-right">
                    <div class="btn-industry">
                        <el-button :disabled="placePageNo == 1" @click="placePrebtn"
                            :class="placePageNo == 1 ? 'left-arrow' : 'left-arrow blue'">
                            <span class="el-icon-arrow-left"></span>
                        </el-button>
                        <el-button :disabled="placeTotalPage == placePageNo" @click="placeNextbtn"
                            :class="placeTotalPage == placePageNo ? 'right-arrow' : ' right-arrow blue'"><i
                                class="el-icon-arrow-right"></i></el-button>
                    </div>
                </div>

            </div>
            <div class="right-industry">
                <div class="flex-between">
                    <span class="title-text">热门推荐</span>
                    <div>
                        <span style="margin-right:5px">换一换</span>
                        <i class="el-icon-refresh" style="cursor: pointer;" @click="changeHot"></i>
                    </div>
                </div>
                <div class="title-divider"></div>
                <div class="artical-text" style="width:100%;margin-top: 40px;">
                    <ul>
                        <li v-for="item in hotList" :key="item.id" @click="openDialog">
                            <div style="display:flex;justify-content: space-between;">

                                <!--  <el-tooltip effect="light" :content="item.title" placement="top">
                                    <span class="text-1">{{ item.title }}</span>
                                </el-tooltip> -->
                                <span class="text-1" :title="item.title">{{ item.title }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="right-industry-img">
                    <div class="right-industry-imgmask"></div>
                </div>
            </div>
        </div>
        <!-- 底部列表 -->
        <articleList></articleList>
        <!-- 底部导航 -->
        <foot></foot>
       <!--  <formDialog :dialogVisible.sync="dialogVisible"></formDialog> -->
    <iframeBox :dialogVisible.sync="dialogVisible"></iframeBox>

    </div>
</template>
<script>
import foot from '@/components/FootNavBar.vue'
import articleList from '@/components/ArticleList.vue'
import iframeBox from '@/components/iframe.vue'

/* import formDialog from '@/components/dialogForm.vue' */
import { topLevelArticleList, getTipList, getContentInfo } from '@/utils/api'
export default {
    name: 'industryPolicy',
    components: {
        
        iframeBox,foot, articleList
    },
    created() {
        this.getIndustryList(this.industryId, 1)
        this.getIndustryList(this.placeId, 1)
        this.topLevelArticleListFn()
        this.getTipListFn()
    },
    data() {
        return {
            imgURL(data) {
                return this.$imgURL + data
            },
            spanList: [
            ],
            safeId: '277',//安全百科
            industryId: '278',//行业政策
            placeId: '280',//地方政策
            dialogVisible: false,
            btnDisabled: '1',//禁用按钮
            recommendCurrentPage: 1,//热门推荐
            articleList: [],//行业政策第一条
            articleLists: [{ text: '冷却塔棕色煤炭', id: 1, url: 'https://t7.baidu.com/it/u=1819248061,230866778&fm=193&f=GIF' }, { text: '化工厂科技人员手拿操作器', id: 2, url: 'https://t7.baidu.com/it/u=1595072465,3644073269&fm=193&f=GIF' }, { text: '工厂智能化场景', id: 3, url: 'https://t7.baidu.com/it/u=2621658848,3952322712&fm=193&f=GIF' }, { text: '生产车间', id: 4, url: 'https://t7.baidu.com/it/u=2359570649,2574326109&fm=193&f=GIF' },],
            industryPageNo: 1,//行业政策
            placePageNo: 1,//地方政策页码
            placeList: [],//地方政策列表
            placeListSplice: [],//地方政策截取后
            textList: [{ text: '双重预防究竟是哪双重，预防了什么？', id: 1, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 2, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 3, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', date: '2022-9-27', id: 4 }, { text: '双重预防究竟是哪双重，预防了什么？', id: 5, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 6, date: '2022-9-27' }, { text: '双重预防究竟是哪双重，预防了什么？', id: 7, date: '2022-9-27' }],
            textList2: [{ text: '《国务院安委会关于实施遏制重特大事故工作指南构建双重预防机制的意见》', id: 1, date: '2022-9-27' }, { text: '《国务院安委会关于实施遏制重特大事故工作指南构建双重预防机制的意见》', id: 2, date: '2022-9-27' }, { text: '《关于实施遏制重特大事故工作指南构建双重预防机制的意见》', id: 3, date: '2022-9-27' },],
            hotList: [{ text: '山东《山东省应急管理厅文件》', id: 1, }, { text: '广州《标本兼治遏制重特大事故工作指南》', id: 2, }, { text: '山东《山东省应急管理厅文件》', id: 3, }, { text: '广州《标本兼治遏制重特大事故工作指南》', id: 4, }, { text: '山东《山东省应急管理厅文件》', id: 5, }, { text: '广州《标本兼治遏制重特大事故工作指南》', id: 6, }, { text: '广州《标本兼治遏制重特大事故工作指南》', id: 7, }],
            industyTotalPage: '',//行业政策总页码
            placeTotalPage: '',//地方政策总页码
            pageSize: 4

        }
    },
    methods: {
        /* 获取政策 */
        getIndustryList(id, pageNo) {
            let that = this
            let params = {
                channelId: id,
                pageNo: pageNo,
                pageSize: that.pageSize
            }
            getContentInfo(params).then(res => {
                if (res.status === 200) {
                    if (id === '278') {
                        if (res.data.content.length > 1) {
                            that.industyTotalPage = Math.ceil(res.data.total / that.pageSize)
                            that.articleList = res.data.content[0]
                            that.articleLists = res.data.content.filter((item, index) => {
                                return index > 0
                            })
                        }
                    } else {
                        if (res.data.content.length > 1) {
                            that.placeTotalPage = Math.ceil(res.data.total / that.pageSize)
                            that.placeList = res.data.content[0]
                            that.placeListSplice = res.data.content.filter((item, index) => {
                                return index > 0
                            })
                        }
                    }

                }
            })
        },
        //打开弹框
        openDialog() {
            this.dialogVisible = true
        },
        //获取热门推荐
        topLevelArticleListFn() {
            let topParams = {
                channelId: this.industryId,
                pageNo: this.recommendCurrentPage,
                sort: 'topLevel',
                pageSize: 10
            }
            topLevelArticleList(topParams).then(res => {
                this.hotList = res
            })
        },
        //获取标签
        getTipListFn() {
            let data = {
                channelId: this.industryId,
                pageNo: 1,
                pageSize: 10,
            };
            getTipList(data).then(res => {
                console.log(res);
                this.spanList = res
            }).catch(err => {
                console.log(err);
            })
        },
        /* 换一换 */
        changeHot() {
            this.recommendCurrentPage++
            let topParams = {
                channelId: this.industryId,
                pageNo: this.recommendCurrentPage,
                sort: 'topLevel',
                pageSize: 7
            }
            this.topLevelArticleListFn(topParams)
        },
        /* 行业政策 */
        preClick() {
            let that = this
            if (that.industryPageNo == 1) {
                that.getIndustryList(that.industryId, that.industryPageNo)
            } else {
                that.industryPageNo--
                that.getIndustryList(that.industryId, that.industryPageNo)
            }

        },
        nextClick() {
            let that = this
            that.industryPageNo++
            that.getIndustryList(that.industryId, that.industryPageNo)
        },
        /* 地方政策 */
        placePrebtn() {
            let that = this
            if (that.placePageNo == 1) {
                that.getIndustryList(that.placeId, that.placePageNo)
            } else {
                that.placePageNo--
                that.getIndustryList(that.placeId, that.placePageNo)
            }
        },
        placeNextbtn() {
            let that = this
            that.placePageNo++
            that.getIndustryList(that.placeId, that.placePageNo)
        },

    }
}

</script>
<style scoped lang="less">
@import '@/css/varible.less';
.hover-span {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #F49D3D;

}

.bottom-btn {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.header-box {
    position: relative;
    background-size: 100% 100%;
    box-sizing: border-box;
    height: 458px;
    background-repeat: no-repeat;
    background-image: url(@/assets/indestry/policyBanner.jpg);
    padding-top: @banner;
    padding-bottom:60px;

    .header-mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: 100% 100%;
        background-image: linear-gradient(90deg, #002F80 1%, rgba(0, 9, 60, 0.00) 100%);
    }

    .header-text {
        position: relative;
        z-index: 99;
        margin: auto;
        width: 1200px;
        color: #fff;

        .header-title {
            font-family: PINGFANG MEDIUM;
            font-size: 48px;
            color: #FFFFFF;
            font-weight: 700;
            margin-bottom: 40px;
        }

        .header-content {
            font-family: PINGFANG MEDIUM;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 30px;
            font-weight: 400;
            width: 579px;
            margin-bottom: 20px;
        }

        .header-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 640px;
            height: 52px;
            background: rgba(0, 4, 10, 0.40);
            border-radius: 6px;

            .btn {
                margin-left: 14px;
                cursor: pointer;
                width: 86px;
                height: 28px;
                font-size: 12px;
                color: #FFFFFF;
                border: none;
                background-image: linear-gradient(180deg, #FFC43C 0%, #F49D3D 100%);
                border-radius: 6px;
            }
        }
    }
}

/* 行业政策 */
.industry {
    padding-top: 66px;
    box-sizing: border-box;

    width: 1180px;
    margin: 0 auto 20px;
    display: flex;
    justify-content: space-between;

    .ul-indeustry {
        ul {
            margin-top: 40px;

            li {
                position: relative;
                margin-bottom: 40px;

                /*  display:flex; */


                i {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #B3CBE7;
                    border: 1px solid rgba(206, 228, 255, 1);
                    margin-right: 5px;
                    box-sizing: border-box;
                }

                span {
                    font-family: PINGFANG MEDIUM;
                    font-size: 16px;
                    color: #3E3A39;
                    font-weight: 500;
                }

                span:last-child {
                    position: absolute;
                    right: 0;
                    font-family: PINGFANG MEDIUM;
                    font-size: 14px;
                    color: #B2B0B0;
                    font-weight: 400;
                }
            }

        }
    }

    .title-text {
        font-family: PINGFANG MEDIUM;
        font-size: 18px;
        color: #3E3A39;
        font-weight: 700;
    }

    .title-divider {
        margin-top: 15px;
        background: #E7ECF0;
        border-radius: 2px;
        height: 1px;
        margin-bottom: 34px;
    }

    .left-industry {
        width: 62%;
        position: relative;

        .industry-law {
            display: flex;
            justify-content: space-between;

            .law-text {
                flex: auto
            }

            .law-img {
                width: 259px;
                height: 243px;
                background: #ccc;
                border: 1px solid rgba(228, 228, 228, 1);
                border-radius: 6px;
                margin-right: 30px;
            }

            .law-text-1 {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            .law-text-1 span:first-child {
                width: 80%;
                //font-family: SourceHanSansCN-Bold;
                font-size: 18px;
                color: #3E3A39;
                font-weight: 700;
            }

            .law-text-1 span:last-child {
                //font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #B2B0B0;
                font-weight: 400;
            }

            .law-content {
                position: relative;
                //font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #3E3A39;
                line-height: 26px;
                font-weight: 400;

                span {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    //font-family: SourceHanSansCN-Regular;
                    font-size: 14px;
                    color: #0D8EFF;
                    font-weight: 400;
                }
            }
        }


        .btn-right {
            text-align: right;

            .btn-industry {
                display: inline-block;

                .left-arrow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: left;
                    width: 38px;
                    height: 30px;
                    background: #F0F2F9;
                    border: 1px solid rgba(223, 228, 238, 1);
                    border-radius: 6px;
                }

                .blue {
                    background: #0D8EFF !important;

                    ::v-deep .el-icon-arrow-right {
                        color: #ebf1ff
                    }

                    ::v-deep .el-icon-arrow-left {
                        color: #ebf1ff
                    }

                }

                .right-arrow {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: right;
                    width: 38px;
                    height: 30px;
                    border: 1px solid rgba(223, 228, 238, 1);
                    background: #F0F2F9;
                    border-radius: 6px;
                    margin-left: 10px;
                }

            }
        }

    }

    .right-industry {
        width: 32%;

        .flex-between {
            display: flex;
            justify-content: space-between;
        }

        .right-industry-img {
            position: relative;
            width: 370px;
            height: 190px;
            background-image: url(../assets/indestry/政策4.jpg);
            background-size: 100% 100%;

            .right-industry-imgmask {
                height: 100%;

            }
        }

        .right-top {
            span {
                cursor: pointer;
                display: inline-block;
                background: #EDF4FF;
                border-radius: 6px;
                //font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: #408CF6;
                font-weight: 500;
                padding: 10px 15px;
                box-sizing: border-box;
                margin-right: 5px;
                margin-bottom: 5px;

            }
        }

        .right-medium {
            border-radius: 6px;
            margin-bottom: 10px;
            position: relative;
            width: 370px;
            height: 190px;
            background-size: 100% 100%;
            background-image: url(../assets/indestry/政策1.jpg);

            .right-medium-mask {
                height: 100%;
                background-image: linear-gradient(90deg, #1A7DD8 0%, rgba(57, 162, 236, 0.00) 100%);
                border-radius: 6px;
            }

            span {
                //font-family: SourceHanSansCN-Bold;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: 700;
                position: absolute;
                top: 20%;
                left: 10%;
            }
        }

        .right-bottom {
            display: flex;
            justify-content: space-between;

            .bottom-box-1 {
                position: relative;
                width: 174px;
                height: 174px;
                background-size: 100% 100%;
                background-image: url(../assets/indestry/政策2.jpg);
                border-radius: 6px;

                .bottom-box1-mask {
                    border-radius: 6px;
                    height: 100%;
                    background-image: linear-gradient(90deg, #8395BD 0%, rgba(57, 162, 236, 0.00) 100%);
                }
            }

            .bottom-box-2 {
                position: relative;
                width: 174px;
                height: 174px;
                background-size: 100% 100%;
                background-image: url(../assets/indestry/政策3.jpg);
                border-radius: 6px;

                .bottom-box2-mask {
                    border-radius: 6px;
                    height: 100%;
                    background-image: linear-gradient(90deg, #20CBA9 0%, rgba(105, 217, 214, 0.00) 100%);
                }
            }

            span {
                //font-family: SourceHanSansCN-Bold;
                font-size: 20px;
                color: #FFFFFF;
                font-weight: 700;
                position: absolute;
                top: 20%;
                left: 10%;
            }
        }
    }
}
</style>