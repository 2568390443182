import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import MetaInfo from "vue-meta-info";
import "element-ui/lib/theme-chalk/index.css";
import EventBus from "./utils/event";
import './utils/register'
ElementUI.Dialog.props.lockScroll.default = false;
import "./css/base.css";
import axios from "axios";
Vue.use(ElementUI);
Vue.use(MetaInfo);
import store from "@/store/index.js";
Vue.config.productionTip = false;
Vue.prototype.$EventBus = EventBus
Vue.prototype.axios = axios;
const protocol = window.location.protocol;
Vue.prototype.$imgURL = `${protocol}//jqd.jxdinfo.com/api`;
new Vue({
  router,
  store,
  metaInfo(){
    return {
      title:this.$store.state.metaInfo.title,
      meta:[
        {
          name:'keywords',
          content:this.$store.state.metaInfo.keywords
        },
        {
          name:'description',
          content:this.$store.state.metaInfo.description

        }
      ]
    }
  },
  el: "#app",
  render: (h) => h(App),
});
